import React, { useState } from "react";

function Turkey() {
	return (
		<div>
			{/* <!-- Main Section --> */}
			<div class="container-about">
				<h1 class="title-un-des">Turkey Registration</h1>
				<div class="container">
					<div class="row">
						<div class="title-un-icon"></div>
						<div className="container-about">
							<p style={{ textAlign: "center" }}>
								Use this form to register for our Turkey giveaway
							</p>
						</div>
						<iframe
							src="https://docs.google.com/forms/d/e/1FAIpQLSdQMVqMCeXvrfq3WspxUYxQroRImVTUT_ZZWx-65WboSp9W3A/viewform?embedded=true"
							width="100%"
							height="900"
							frameborder="0"
							marginheight="0"
							marginwidth="0"
						>
							Loading…
						</iframe>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Turkey;
