import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	return (
		<header className="site-header">
			<div className="header-inner">
				{/* navigation panel */}
				<div className="container">
					<div className="row">
						<div className="header-table col-md-12">
							<div className="brand">
								<NavLink to="/">
									<img
										src="images/logo/jumc-logo.svg"
										alt="Jones Memorial United Methodist Church"
									/>
								</NavLink>
							</div>
							<nav id="nav-wrap" className="main-nav">
								<div id="mobnav-btn" onClick={toggleMenu}></div>
								<ul
									className={`sf-menu ${isMenuOpen ? "open" : ""}`}
									id="mobile-nav"
								>
									<li>
										<NavLink to="/" exact>
											Home
										</NavLink>
									</li>
									<li>
										<div className="dropdown">
											<NavLink to="/about">About Us &#9660;</NavLink>
											<div class="dropdown-content">
												<NavLink to="/history">History</NavLink>
												<NavLink to="/leadership">Leadership</NavLink>
											</div>
										</div>
									</li>
									<li>
										<NavLink to="/calendar">Calendar</NavLink>
									</li>
									<li>
										<NavLink to="/gallery">Photo Gallery</NavLink>
									</li>
									<li>
										<NavLink to="/newsPage">News</NavLink>
									</li>
									<li>
										<NavLink to="/give">Give</NavLink>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
				{/* End navigation panel */}
			</div>
		</header>
	);
}

export default Header;
