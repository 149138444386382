import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import CustomSlider from "../components/CustomSlider";
import { useNavigate } from "react-router-dom";

function Give() {
	const [sliderItems, setSliderItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, "events"));
				const items = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					imageUrl: doc.data().imageUrl,
					title: doc.data().title,
					subtitle: doc.data().subtitle,
					date: doc.data().date,
				}));
				setSliderItems(items);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="box-layout">
			<div className="slider-wrapper" style={{ height: "200px" }}>
				<CustomSlider images={sliderItems} />
			</div>
			{/* End Slider 	 Main Section */}
			<div className="backbutton">
				<p onClick={() => navigate("/")}>&larr; Back to Home</p>
			</div>
			<h2 className="intro">
				Giving to Jones Memorial United Methodist Church
			</h2>
			<p className="title-un-des">
				Give to the Jones Memorial United Methodist Church. This allow us to
				continue our mission.
			</p>
			<div>
				{/* <!-- https://www.paypal.com/donate/?hosted_button_id=24PF38RWB996U --> */}
				<center>
					<h1>
						<form
							action="https://www.paypal.com/donate"
							method="post"
							target="_top"
						>
							<input
								type="hidden"
								name="hosted_button_id"
								value="24PF38RWB996U"
							/>
							<input
								type="image"
								src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
								border="0"
								name="submit"
								title="PayPal - The safer, easier way to pay online!"
								alt="Donate with PayPal button"
								style={{ height: "auto" }}
							/>
							<img
								alt=""
								border="0"
								src="https://www.paypal.com/en_US/i/scr/pixel.gif"
								width="1"
								height="1"
							/>
						</form>
					</h1>
				</center>
			</div>
		</div>
	);
}
export default Give;
