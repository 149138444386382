import React, { useState } from "react";

function History() {
	return (
		<div>
			{/* <!-- Main Section --> */}
			<div class="container-about">
				<h2 class="title-un-des">
					JONES MEMORIAL UNITED METHODIST CHURCH HISTORICAL REFLECTION
				</h2>
				<div class="container">
					<div class="row">
						<div class="title-un-icon"></div>
						<h4 style={{ textAlign: "center", color: "#000000" }}>
							“We’ve Come This Far by Faith”
						</h4>
						<p style={{ textAlign: "center" }} class="title-un-des">
							After several years of gathering as a mission of Ebenezer
							Methodist Church, a white congregation, the Jones Mission was
							formally organized and recognized as Benning Methodist Church, DC
							on October 27, 1864, by the Washington Annual Conference of the
							Methodist Episcopal Church (now the United Methodist Church).
							Jones Memorial was named for the original organizer and leader of
							the mission, Noah Jones, who was a black preacher who agreed to
							lead the mission when black members of Ebenezer wanted to worship
							God in a church of their own. Jones represented “Benning” at that
							1864 Annual Conference in Baltimore, Maryland and when the session
							ended, Rev. William Hicks, an organizing member was appointed as
							the first pastor.
							<br></br>
							<p style={{ margin: "1.5em" }}>
								The newly formed Benning Church worshipped in a building on a
								site donated by Brother John Payne next to Payne’s Cemetery at
								46th and Benning Road NE. Lumber was donated by Brother Ozzie
								Jackson and with prayers, gifts, toil, earnestness, and
								determination, Jones Mission had erected her first building by
								1863, before officially being recognized by the conference as a
								church. The original church building was destroyed by a fire in
								the early 1870s and was soon rebuilt due to the determination
								and earnestness of members like Sister Mary P. Jackson, who
								collected over $25.00 towards the rebuilding efforts while the
								church building was still burning. The members of Benning
								worshipped in the second building until growth in membership
								required the building of a new edifice in 1887.
							</p>
							<p style={{ margin: "1.5em" }}>
								As the church continued to grow and ministries continued to
								flourish, a more desirable and convenient location was purchased
								during the pastorate of Rev. Caleb Ellsworth Queen, near what
								was then 44th and Benning in 1920. The congregation’s fourth
								building and a parsonage were completed in 1923 at a cost of
								$18,000. Several church ministries and organizations were
								started during this time, including the Usher Board, a Church
								School, an Epworth League, and the Ladies Missionary Society.
							</p>
							<p style={{ margin: "1.5em" }}>
								In 1939 three branches of American Methodism merged. The
								Methodist Episcopal (which Benning and later Jones Memorial were
								a part of), the Methodist Episcopal South, and the Methodist
								Protestant churches became the Methodist Church. The Methodist
								Episcopal South had previously split from the Methodist
								Episcopal Church in 1844 over the issue of slavery and was now
								reuniting, with one major caveat. As a concession to the
								Methodist Episcopal South, the Central Jurisdiction was formed
								to segregate black members and clergy within the predominantly
								white denomination.
							</p>
							<p style={{ margin: "1.5em" }}>
								Jones Memorial Methodist Church continued to grow in strength
								and numbers and was served by several faithful and dynamic
								pastors. During the ten-year pastorate of Rev. Kenneth Pearle
								Barnes, who was appointed in 1943, the mortgages on the church
								and parsonage were liquidated. During the pastorate of Rev.
								Thomas Beecher Travis, a Building Committee was organized in
								1954 and it was determined that needed expansion would require
								the acquisition of a larger site. In 1959, under the pastorate
								of Rev. Stanford Jerome Harris, D.D., 51,382 sq. ft. of land in
								the 4600 block of G Street SE (near the site of the original
								Jones Chapel) was purchased for $23,478. In 1966, Jones Memorial
								broke ground on phase one of a two-phase building plan. The
								construction of the $250,000 educational building began in 1967
								and was completed in 1968. The church used the social hall of
								the new building as its sanctuary until completion of the second
								phase was completed in 1979 under the pastorate of Rev. Marcus
								Matthews.
							</p>
							<p style={{ margin: "1.5em" }}>
								As the community transforms, Jones Memorial United Methodist
								Church remains as a pillar of faith and service. Jones Memorial
								partners with other churches and community organizations to
								expand our ministry reach. Since the pandemic, a renewed
								emphasis on community outreach led to the establishment of the
								Jones Memorial UMC Energy Conservation Connection (JECC).
								Through the JECC, our church helps bring necessary resources to
								ensure better stewardship of the environment God has created. We
								stand poised to continue our mission to be God's Beloved
								Community in Washington, DC by caring for God's people and God's
								planet. Over the years Jones Memorial United Methodist Church
								has been blessed with 45 pastors, and many members and families
								still associated with the church call it their “home.” Praise be
								to God!
							</p>
						</p>
						<h4
							class="title-un-des"
							style={{ textAlign: "center", color: "#000000" }}
						>
							WE ARE PROUD, WE ARE HONORED, WE ARE BLESSED!
						</h4>
					</div>
				</div>

				{/* <!-- End Blog Section --> */}
			</div>
			{/* <!-- Main Section --> */}
		</div>
	);
}

export default History;
