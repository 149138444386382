import React, { useState } from "react";

function Calendar() {
	return (
		<div className="box-layout">
			<div class="container-about">
				<h1 class="title-un-des">Monthly Calendar</h1>
				<div class="container">
					<iframe
						title="Calendar"
						src="https://calendar.google.com/calendar/embed?src=revbjhall%40gmail.com&ctz=America%2FNew_York"
						style={{ border: "solid 1px #777" }}
						width="100%"
						height="600"
						frameborder="0"
						scrolling="yes"
					></iframe>
				</div>
			</div>
		</div>
	);
}
export default Calendar;
