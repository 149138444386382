function formatDate(inputdate) {
	if (!inputdate) {
		return " ";
	}

	const date = new Date(inputdate);
	const formattedDate = date.toLocaleDateString("en-US", {
		month: "long",
		day: "numeric",
		year: "numeric",
	});

	return formattedDate;
}

export default formatDate;
