import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import CustomSlider from "../components/CustomSlider";

function Thankyou() {
	const [sliderItems, setSliderItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, "events"));
				const items = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					imageUrl: doc.data().imageUrl,
					title: doc.data().title,
					subtitle: doc.data().subtitle,
					date: doc.data().date,
				}));
				setSliderItems(items);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="box-layout">
			<div className="slider-wrapper" style={{ height: "200px" }}>
				<CustomSlider images={sliderItems} />
			</div>
			{/* End Slider 	 Main Section */}

			<div class="container-about">
				<h1 class="title-un-des">Thank You</h1>
				<div class="container">
					<div class="row">
						<div class="title-un-icon"></div>
						<h3 class="title-un-des">
							Thank you for having interest in our organization we will reach
							out to you soon.
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Thankyou;
