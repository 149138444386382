import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from "react-share";

import formatTimestamp from "../components/Timestamp";

function ArticlePage() {
	const { id } = useParams();
	const [article, setArticle] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const docRef = doc(db, "articles", id);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					setArticle(docSnap.data());
				} else {
					console.log("No such document!");
				}
			} catch (error) {
				console.error("Error fetching article:", error);
			}
		};

		fetchArticle();
	}, [id]);

	return (
		<div>
			<div className="backbutton">
				<p onClick={() => navigate(-1)}>&larr; Back to News Page</p>
			</div>
			{article && (
				<div>
					<div style={{ textAlign: "center" }}>
						<h1>{article.title}</h1>
					</div>
					<div className="title-un-icon"></div>
					<br></br>
					<p style={{ textAlign: "center" }}>
						{formatTimestamp(article.createdAt)}
					</p>
					<div className="row2">
						<FacebookShareButton
							url={`https://jonesmemorialumc.org/newsPage/${article.id}`} // Replace with your website URL
							quote={article.title}
							hashtag="JonesUMCNews"
						>
							<i className="icon2 ion-social-facebook"></i>
						</FacebookShareButton>
						<TwitterShareButton
							url={`https://jonesmemorialumc.org/newsPage/${article.id}`} // Replace with your website URL
							title={article.title}
							hashtag="JonesUMCNews"
						>
							<i className="icon2 fa-brands fa-x-twitter"></i>
						</TwitterShareButton>
						<LinkedinShareButton
							url={`https://jonesmemorialumc.org/newsPage/${article.id}`} // Replace with your website URL
							title={article.title}
							hashtag="JonesUMCNews"
						>
							<i className="icon2 ion-social-linkedin"></i>
						</LinkedinShareButton>
					</div>
					<br></br>
					<div className="blog-post-g">
						<div
							// style={{ justifyContent: "center", margin: "0 auto" }}
							className="post-img"
						>
							<img
								style={{ justifyContent: "center", margin: "0 auto" }}
								src={article.imageUrl}
								alt={article.title}
							/>
						</div>
						<p style={{ padding: "50px 100px" }}>{article.content}</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default ArticlePage;
