import React, { useState } from "react";

function Leadership() {
	return (
		<div>
			{/* <!-- Main Section --> */}
			<div class="container-about">
				<h1 class="title-un-des">Leadership</h1>
				<div class="container">
					<div class="row">
						<div class="title-un-icon"></div>

						<br></br>
						<ul class="blog-posts-g">
							<li>
								<div class="post-img">
									<a href="#">
										<img src="../images/posts/revhall.jpg" />
									</a>
								</div>
								<div class="about-content">
									<h5>
										<a href="#"> Pastor </a>
									</h5>
									<div class="post-info">
										<span> Rev. Benjamin J. Hall</span>
										<br></br>
										<a href="pastor@jonesmemorialumc.org">
											pastor@jonesmemorialumc.org
										</a>
									</div>
								</div>
							</li>
							<li>
								<div class="post-img">
									<a href="#">
										<img
											style={{ width: "60%" }}
											src="../images/posts/swharton.png"
										/>
									</a>
								</div>
								<h5>
									<a href="#"> Certified Lay Minister</a>
								</h5>
								<div class="post-info">
									<span>Min. Sharee Wharton</span>
								</div>
							</li>
							<li>
								<h5>
									<a href="#"> Jones Memorial Unified Board</a>
								</h5>
								<div class="post-info">
									<a>Chair – Sis. Catherine Spears</a>
									<br></br>
									<a>Adult Ministries – Sis. Rosa Jackson</a>
									<br></br>
									<a>Children's Ministries – Sis. Joyce Lee</a>
									<br></br>
									<a>Education – Sis. Myra Newbill</a>
									<br></br>
									<a>Finance – Sis. Myra Newbill</a>
									<br></br>
									<a>Lay Member(s) – Sis. Louann Greene</a>
									<br></br>
									<a>Membership – Sis. Rosa Jackson</a>
									<br></br>
									<a>Nominations – Sis. Gloria Robinson</a>
									<br></br>
									<a>SPRC – Sis. Rachel Blue</a>
									<br></br>
									<a>Treasurer – Sis. Carla Gaskins</a>
									<br></br>
									<a>Trustee – Bro. Sterling Preston</a>
									<br></br>
									<a>UM Men – Vacant</a>
									<br></br>
									<a>UM Women – Sis. Sylvia Preston</a>
									<br></br>
									<a>Worship – Sis. Bonita Barron </a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* <!-- End Blog Section --> */}
		</div>
	);
}

export default Leadership;
