import React, { useState } from "react";

function About() {
	return (
		<div>
			{/* <!-- Main Section --> */}
			<div class="container-about">
				<h1 class="title-un-des">About Us</h1>
				<div class="container">
					<div class="row">
						<div class="title-un-icon"></div>
						<p style={{ textAlign: "center" }} class="title-un-des">
							Jones Memorial United Methodist Church is a family-focused
							community of faith located in the Benning Heights community of
							southeast Washington, DC. For over 160 years, the mission of Jones
							Memorial has been to share the Gospel of Jesus Christ and make
							disciples for the transformation of the world.
							<br></br>
							<p style={{ margin: "1.5em" }}>
								Jones Memorial is in the Greater Washington District of the
								Baltimore-Washington Conference of the United Methodist Church.
								Our Episcopal Leader is Bishop LaTrelle Easterling.
							</p>
						</p>
					</div>
					<div class="row">
						<h2 class="title-un-des" style={{ textAlign: "center" }}>
							Service
						</h2>

						<p style={{ textAlign: "center" }} class="title-un-des">
							Sunday Worship Service ▪ 11:00 am
							<br></br>
							Children’s Sunday School ▪ 11:00 am (1st & 3rd Sundays)
							<br></br>
							Wednesday Bible Study (Zoom)* ▪ 6:30 pm
							<br></br>
							<p style={{ margin: "1.5em" }}>
								*The Zoom links for Sunday Worship and Bible Study can be
								requested by emailing info@jonesmemorialumc.org
							</p>
						</p>
					</div>
				</div>

				{/* <!-- Blog Section --> */}
			</div>
		</div>
	);
}

export default About;
