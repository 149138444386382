import React from "react";

class Logout extends React.Component {
	render() {
		return (
			<div class="container-about">
				<h1 class="title-un-des">Log Out Successful</h1>
				<br />
				<div class="container">
					<div class="newsletter-container wow fadeInUp">
						<div class="title-un-icon"></div>
						<br />
					</div>
				</div>
			</div>
		);
	}
}
export default Logout;
