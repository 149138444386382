import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import formatdate from "./DateFormat";

function CustomSlider({ images }) {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [previousSlide, setPreviousSlide] = useState(currentSlide - 1);
	const navigate = useNavigate();

	const handleNextSlide = () => {
		setCurrentSlide((prevIndex) => (prevIndex + 1) % images.length);
		setPreviousSlide(currentSlide);
	};

	const handlePreviousSlide = () => {
		setCurrentSlide(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
		setPreviousSlide(currentSlide);
	};

	const handleImageClick = () => {
		const currentImage = images[currentSlide];
		const eventId = currentImage.id;
		navigate(`/events/${eventId}`);
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setPreviousSlide(currentSlide);
			setCurrentSlide((prevIndex) => (prevIndex + 1) % images.length);
		}, 6000); // Adjust the interval time as needed

		return () => clearInterval(intervalId);
	}, [images, currentSlide]);

	return (
		<div className="slider-container">
			<img
				className="prev-arrow"
				disabled={currentSlide === 0}
				onClick={handlePreviousSlide}
				src={"../images/arrows/arrow-left.svg"}
				alt="right-arrow"
			/>
			<div className="slider-inner">
				{images.map((image, index) => (
					<div
						key={index}
						className={`newslide ${
							index === currentSlide
								? "active"
								: index === previousSlide
								? "previous"
								: ""
						}`}
						style={{ transform: `translateX(-${index * 100}%)` }}
						onClick={() => handleImageClick(image.id)}
					>
						<img
							className="slide-image"
							src={image.imageUrl}
							alt={image.title}
							style={{ width: "100%", height: "500px", objectFit: "cover" }}
						/>

						<div className="slide-caption">
							<h1>{image.title}</h1>
							<h5>{image.subtitle}</h5>
							<h5>{formatdate(image.date)}</h5>
						</div>
					</div>
				))}
			</div>

			<img
				className="next-arrow"
				disabled={currentSlide === images.length - 1}
				onClick={handleNextSlide}
				src={"../images/arrows/arrow-right.svg"}
				alt="right-arrow"
			/>
		</div>
	);
}

export default CustomSlider;
