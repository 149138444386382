import React, { useState, useEffect } from "react";
import {
	addDoc,
	collection,
	getDocs,
	deleteDoc,
	doc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { useNavigate, Link } from "react-router-dom";

import { db, storage } from "../firebase.config";

import { useAuth } from "../AuthContext";
import formatDate from "../components/DateFormat";

function Events() {
	const [image, setImage] = useState(null);
	const [otherFile, setOtherFile] = useState(null); // New state variable for the second file
	const [title, setTitle] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [date, setDate] = useState("");
	const [error, setError] = useState(null);
	const [progress, setProgress] = useState(0);
	const [images, setImages] = useState([]);
	const navigate = useNavigate();

	const { user } = useAuth();

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Check if both image and otherFile are selected
		if (image && otherFile) {
			const storageRefImage = ref(storage, `images/${image.name}`);
			const uploadTaskImage = uploadBytesResumable(storageRefImage, image);

			const storageRefOther = ref(storage, `otherFiles/${otherFile.name}`);
			const uploadTaskOther = uploadBytesResumable(storageRefOther, otherFile);

			uploadTaskImage.on(
				"state_changed",
				(snapshot) => {
					const progressPercentImage = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					setProgress(progressPercentImage);
				},
				(error) => {
					console.error("Error uploading image:", error);
					setError("Error uploading image");
				},
				async () => {
					const downloadURLImage = await getDownloadURL(
						uploadTaskImage.snapshot.ref
					);

					uploadTaskOther.on(
						"state_changed",
						(snapshot) => {
							const progressPercentOther = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							);
							setProgress(progressPercentOther); // Update progress for both uploads
						},
						(error) => {
							console.error("Error uploading other file:", error);
							setError("Error uploading other file");
						},
						async () => {
							const downloadURLOther = await getDownloadURL(
								uploadTaskOther.snapshot.ref
							);

							addDoc(collection(db, "events"), {
								imageUrl: downloadURLImage,
								otherFileUrl: downloadURLOther,
								title: title,
								subtitle: subtitle,
								date: date,
							})
								.then(() => {
									console.log(
										"Image and other file uploaded and data added to Firestore"
									);
									// Reset form fields
									setImage(null);
									setOtherFile(null);
									setTitle("");
									setSubtitle("");
									setDate("");
									setProgress(0);
									// Fetch updated image list
									fetchImages();
									navigate("/confirmation");
								})
								.catch((error) => {
									console.error("Error adding data to Firestore:", error);
									setError("Error adding data to Firestore");
								});
						}
					);
				}
			);
		} else {
			setError("Please select an image and a file");
		}
	};

	const fetchImages = async () => {
		const querySnapshot = await getDocs(collection(db, "events"));
		const imagesData = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			imageUrl: doc.data().imageUrl,
			otherFileUrl: doc.data().otherFileUrl,
			title: doc.data().title,
			subtitle: doc.data().subtitle,
			date: doc.data().date,
		}));
		setImages(imagesData);
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
	};

	const handleOtherFileChange = (e) => {
		setOtherFile(e.target.files[0]);
	};

	const handleCancel = () => {
		setImage(null);
		setOtherFile(null);
		setTitle("");
		setSubtitle("");
		setDate("");
		setError(null);
		setProgress(0);
	};

	const handleDelete = async (id) => {
		if (window.confirm("Are you sure you want to delete this event?")) {
			try {
				await deleteDoc(doc(db, "events", id));
				// Update the state directly to remove the deleted event
				setImages(images.filter((event) => event.id !== id));
				console.log("Event deleted successfully");
			} catch (error) {
				console.error("Error deleting event:", error);
			}
		}
	};

	useEffect(() => {
		fetchImages();
	}, []);

	return (
		<div className="container-about">
			<h1 className="title-un-des">Events</h1>
			<div className="title-un-icon"></div>
			<br></br>
			<div className="container">
				<div className="newsletter-container wow fadeInUp">
					<form className="eventblock" onSubmit={handleSubmit}>
						<div className="input-wrapper">
							<label for="carouselimage" className="button">
								Home Page Image
							</label>
							<input
								style={{ display: "none" }}
								id="carouselimage"
								type="file"
								onChange={(e) => setImage(e.target.files[0])}
							/>
							{image && (
								<img
									style={{
										height: "150px",
										width: "150px",
									}}
									src={URL.createObjectURL(image)}
									alt="Preview"
								/>
							)}
						</div>
						<div className="input-wrapper">
							<label for="Document" className="button">
								Upload a Document
							</label>
							<input
								style={{ display: "none" }}
								id="Document"
								type="file"
								onChange={(e) => setOtherFile(e.target.files[0])}
							/>
						</div>
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
						</div>
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Subtitle"
								value={subtitle}
								onChange={(e) => setSubtitle(e.target.value)}
							/>
						</div>
						<div className="input-wrapper">
							<input
								type="date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
							/>
						</div>
						<br></br>
						<div>
							<button className="button green-button" type="submit">
								Upload
							</button>{" "}
							&nbsp;
							<button className="button" type="button" onClick={handleCancel}>
								Cancel
							</button>
							{error && <p>{error}</p>}
							{progress > 0 && <p>Upload Progress: {progress}%</p>}
						</div>
					</form>
				</div>
				<div className="container-about" style={{ padding: "20px" }}>
					<h3 className="title-un">Uploaded Events</h3>
					<div className="title-un-icon"></div>
					<br></br>
					<ul className="blog-posts-g">
						{images.map((image) => (
							<li key={image.id}>
								<div className="post-img">
									{image.imageUrl && (
										<Link to={`/events/${image.id}`}>
											<img src={image.imageUrl} alt={image.title} />
										</Link>
									)}
									{image.otherFileUrl && (
										<a
											href={image.otherFileUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											Download Other File
										</a>
									)}
									{user && (
										<button
											className="button"
											onClick={() => handleDelete(image.id)}
										>
											Delete
										</button>
									)}
								</div>
								<div className="post-content">
									<h5>
										<a>{image.title}</a>
									</h5>
									<div className="post-info">
										<a>{image.subtitle}</a> <br></br>
										<a>{formatDate(image.date)}</a>
									</div>
								</div>
								{user && (
									<button
										className="button mobile-button"
										onClick={() => handleDelete(image.id)}
									>
										Delete
									</button>
								)}
							</li>
						))}
					</ul>
					<br></br>
				</div>
			</div>
		</div>
	);
}

export default Events;
