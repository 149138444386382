import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Calendar from "./pages/calendar";
import Gallery from "./pages/gallery";
import Give from "./pages/give";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Dashboard from "./pages/dashboard";
import Events from "./pages/events";
import PhotoUpload from "./pages/photoUpload";
import ArticleUpload from "./pages/articleUpload";
import ArticlePage from "./pages/articlePage";
import NewsPage from "./pages/NewsPage";
import Thankyou from "./pages/thank_you";
import ConfirmationPage from "./pages/confirmation";
import EventPage from "./pages/EventPage";
import Leadership from "./pages/Leadership";
import History from "./pages/History";
import Turkey from "./pages/turkey_registration";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/protected";

import { AuthProvider } from "./AuthContext";

// import PhotoUpload2 from "./pages/photoUpload2";
// import Gallery2 from "./pages/gallery2";

import "./App.css";

function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<Header />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/calendar" element={<Calendar />} />
					<Route path="gallery" element={<Gallery />} />
					<Route path="/give" element={<Give />} />
					<Route path="/login" element={<Login />} />
					<Route path="/thank_you" element={<Thankyou />} />
					<Route path="/logout" element={<Logout />} />
					<Route path="/newsPage" element={<NewsPage />} />
					<Route path="/newsPage/:id" element={<ArticlePage />} />
					<Route path="/events/:id" element={<EventPage />} />
					<Route path="/leadership" element={<Leadership />} />
					<Route path="/history" element={<History />} />
					<Route path="/turkey_registration" element={<Turkey />} />
					{/* <Route path="/photoUpload2" element={<PhotoUpload2 />} /> */}
					{/* <Route path="/Gallery2" element={<Gallery2 />} /> */}
					<Route
						path="/dashboard"
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/photoUpload"
						element={
							<ProtectedRoute>
								<PhotoUpload />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/articleUpload"
						element={
							<ProtectedRoute>
								<ArticleUpload />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/events"
						element={
							<ProtectedRoute>
								<Events />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/confirmation"
						element={
							<ProtectedRoute>
								<ConfirmationPage />
							</ProtectedRoute>
						}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
