import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";

import formatDate from "../components/DateFormat";

function EventPage() {
	const { id } = useParams();
	const [event, setEvent] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchEvent = async () => {
			try {
				const docRef = doc(db, "events", id);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					setEvent(docSnap.data());
				} else {
					console.log("No such document!");
				}
			} catch (error) {
				console.error("Error fetching event:", error);
			}
		};

		fetchEvent();
	}, [id]);

	return (
		<div>
			<div className="backbutton">
				<p onClick={() => navigate("/")}>&larr; Back to Home</p>
			</div>
			{event && (
				<div>
					<div style={{ textAlign: "center" }}>
						<h1>{event.title}</h1>
					</div>
					<div className="title-un-icon"></div>
					<h3 style={{ textAlign: "center" }}>{event.subtitle}</h3>
					<br></br>
					<p style={{ textAlign: "center" }}>{formatDate(event.date)}</p>
					{event.otherFileUrl ? (
						<div className="box-layout">
							<div class="container-about" align="center">
								<iframe
									style={{
										width: "100%",
										height: "800px",
										border: "none",
									}}
									src={event.otherFileUrl}
									title="PDF Document"
								/>
							</div>
						</div>
					) : (
						<p
							className="downloadbutton"
							style={{ textAlign: "center", cursor: "pointer" }}
							href={event.otherFileUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Download Document
						</p>
					)}
					<br></br>
				</div>
			)}
		</div>
	);
}

export default EventPage;
