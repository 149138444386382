// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBBZ3x9t3TcGFIJo4HIo_IJEsST8UwYNQE",
	authDomain: "jones-umc.firebaseapp.com",
	projectId: "jones-umc",
	storageBucket: "jones-umc.appspot.com",
	messagingSenderId: "273304601261",
	appId: "1:273304601261:web:e0b4b0ed01809f9a583c24",
	measurementId: "G-3B7VLX6Y73",
};

export default firebaseConfig;
