import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import {
	collection,
	getDocs,
	deleteDoc,
	doc,
	orderBy,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from "react-share";

import formatTimestamp from "../components/Timestamp";

import { useAuth } from "../AuthContext";

function NewsPage() {
	const [articles, setArticles] = useState([]);
	const { user } = useAuth();

	useEffect(() => {
		const fetchArticles = async () => {
			try {
				const querySnapshot = await getDocs(
					collection(db, "articles"),
					orderBy("createdAt", "desc")
				);
				const articlesData = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					title: doc.data().title,
					content: doc.data().content,
					imageUrl: doc.data().imageUrl,
					createdAt: doc.data().createdAt,
				}));
				setArticles(articlesData);
			} catch (error) {
				console.error("Error fetching articles:", error);
			}
		};

		fetchArticles();
	}, []);

	const handleDelete = async (id) => {
		if (window.confirm("Are you sure you want to delete this article?")) {
			try {
				await deleteDoc(doc(db, "articles", id));
				// Update the state directly to remove the deleted article
				setArticles(articles.filter((article) => article.id !== id));
				console.log("Article deleted successfully");
			} catch (error) {
				console.error("Error deleting article:", error);
			}
		}
	};

	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 style={{ textAlign: "center" }}>Jones UMC News</h1>
						<div className="title-un-des"></div>
						<div className="title-un-icon"></div>
						<br></br>
						{/* <p style={{ textAlign: "center" }}>
							Here are all of your past events
						</p> */}
						<div className="blog-posts-g">
							{articles
								.slice()
								.reverse()
								.map((article) => (
									<li key={article.id}>
										<div className="post-img">
											<Link to={`/newsPage/${article.id}`}>
												<img src={article.imageUrl} alt={article.title} />
											</Link>

											{user && (
												<button
													className="button"
													onClick={() => handleDelete(article.id)}
												>
													Delete
												</button>
											)}
										</div>

										<Link to={`/newsPage/${article.id}`}>{article.title}</Link>
										<p>{formatTimestamp(article.createdAt)}</p>
										<div className="post-content">{article.content}</div>
										<div className="row2">
											<FacebookShareButton
												url={`jones-umc.org/newsPage/${article.id}`} // Replace with your website URL
												quote={article.title}
												hashtag="JonesUMCNews"
											>
												<i className="icon2 ion-social-facebook"></i>
											</FacebookShareButton>
											<TwitterShareButton
												url={`jones-umc.org/newsPage/${article.id}`} // Replace with your website URL
												title={article.title}
												hashtag="JonesUMCNews"
											>
												<i className="icon2 fa-brands fa-x-twitter"></i>
											</TwitterShareButton>
											<LinkedinShareButton
												url={`jones-umc.org/newsPage/${article.id}`} // Replace with your website URL
												title={article.title}
												hashtag="JonesUMCNews"
											>
												<i className="icon2 ion-social-linkedin"></i>
											</LinkedinShareButton>
										</div>
										{user && (
											<button
												className="button mobile-button"
												onClick={() => handleDelete(article.id)}
											>
												Delete
											</button>
										)}
									</li>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewsPage;
