function formatTimestamp(timestamp) {
	if (!timestamp) {
		return " ";
	}
	const date = new Date(timestamp.seconds * 1000);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	return date.toLocaleDateString(undefined, options);
}

export default formatTimestamp;
