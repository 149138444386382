import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../firebase.config";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function ArticleUpload() {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [image, setImage] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!title || !content || !image) {
			setError("Please fill in all fields");
			return;
		}

		setIsUploading(true);
		setError(null);

		const storageRef = ref(storage, `images/${image.name}`);
		const uploadTask = uploadBytesResumable(storageRef, image);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadProgress(progress);
			},
			(error) => {
				console.error("Error uploading image:", error);
				setError("Error uploading image");
				setIsUploading(false);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref)
					.then((imageUrl) => {
						addDoc(collection(db, "articles"), {
							title,
							content,
							imageUrl,
							createdAt: Timestamp.now(),
						})
							.then(() => {
								console.log("Article uploaded successfully");
								setTitle("");
								setContent("");
								setImage(null);
								setUploadProgress(0);
								setIsUploading(false);
								navigate("/confirmation");
							})
							.catch((error) => {
								console.error("Error adding article to Firestore:", error);
								setError("Error adding article to Firestore");
								setIsUploading(false);
							});
					})
					.catch((error) => {
						console.error("Error getting image URL:", error);
						setError("Error uploading image");
						setIsUploading(false);
					});
			}
		);
	};

	const handleCancel = () => {
		setTitle([]);
		setContent([]);
		setImage([]);
		setIsUploading(false);
		setUploadProgress(0);
	};

	return (
		<div className="eventblock">
			<h2 style={{ textAlign: "center" }}>Add New Article</h2>
			<form style={{ textAlign: "center" }} onSubmit={handleSubmit}>
				<div>
					<label for="blogphoto" className="button">
						Upload Image
					</label>
					<input
						id="blogphoto"
						style={{ display: "none" }}
						type="file"
						onChange={(e) => setImage(e.target.files[0])}
					/>
					{image && (
						<img
							style={{ margin: "auto", height: "400px" }}
							src={URL.createObjectURL(image)}
							alt="Preview"
						/>
					)}
				</div>
				<br></br>
				<div>
					<label>Title:</label>
					<input
						type="text"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
				<br></br>
				<div>
					<label>Content:</label>
					<textarea
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
				</div>
				<br></br>
				<button
					className="button green-button"
					type="submit"
					disabled={isUploading}
				>
					{isUploading ? "Uploading..." : "Submit"}
				</button>
				&nbsp;
				<button
					className="button"
					onClick={handleCancel}
					disabled={!isUploading}
				>
					Cancel
				</button>
				{error && <p>{error}</p>}
				{/* {isUploading && <p>Upload Progress: {uploadProgress.progress}%</p>} */}
			</form>
			<br></br>
		</div>
	);
}

export default ArticleUpload;
