import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
	return (
		<div className="box-layout">
			<div class="container-about">
				<h1 class="title-un-des">Dash Board</h1>
				<br />
				<div class="container">
					<div class="newsletter-container wow fadeInUp">
						<div class="title-un-icon"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<br></br>
									<ul className="blog-posts-g">
										<li>
											<div className="post-img">
												<p>
													<NavLink to="/events">
														<img src="images/posts/post-1.png" alt="" />
													</NavLink>
												</p>
											</div>
											<div className="post-content">
												<h3>Events</h3>
											</div>
										</li>
										<li>
											<div className="post-img">
												<p>
													<NavLink to="/articleUpload">
														<img src="images/posts/post-2.png" alt="post 2" />
													</NavLink>
												</p>
											</div>
											<div className="post-content">
												<h3>Articles</h3>
											</div>
										</li>
										<li>
											<div className="post-img">
												<p>
													<NavLink to="/photoUpload">
														<img src="images/posts/post-3.png" alt="" />
													</NavLink>
												</p>
											</div>
											<div className="post-content">
												<h3>Photos</h3>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
