import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import firebaseConfig from "../env";

const app = initializeApp(firebaseConfig);

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			user: null,
			error: null,
		};
	}

	componentDidMount() {
		this.auth = getAuth(app);
		this.auth.onAuthStateChanged((user) => {
			this.setState({ user });
		});
	}

	handleInputChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	handleSignIn = async (event) => {
		event.preventDefault();
		try {
			const { email, password } = this.state;
			await signInWithEmailAndPassword(this.auth, email, password);
		} catch (error) {
			this.setState({ error: error.message });
		}
	};

	handleSignOut = async () => {
		try {
			await signOut(this.auth);
		} catch (error) {
			this.setState({ error: error.message });
		}
	};

	render() {
		const { email, password, user, error } = this.state;

		return (
			<div class="container-about">
				<h1 class="title-un-des">Log In</h1>
				<br />
				<div class="container">
					<div class="newsletter-container wow fadeInUp">
						<br />
						{user ? (
							<div>
								<p>Login Successful</p>
								<div class="title-un-icon"></div>
								<br></br>
								<div>
									<button type="" className="button green-button">
										<NavLink to="/dashboard">To Dashboard</NavLink>
									</button>
								</div>
							</div>
						) : (
							<div>
								<p>user log in.</p>
								<div class="title-un-icon"></div>
								{error && <p>{error}</p>}
								<form style={{ padding: "20px" }} onSubmit={this.handleSignIn}>
									<div>
										<input
											type="email"
											name="email"
											value={email}
											onChange={this.handleInputChange}
											placeholder="Email"
										/>
									</div>
									<br />
									<div>
										<input
											type="password"
											name="password"
											value={password}
											onChange={this.handleInputChange}
											placeholder="Password"
										/>
									</div>
									<br />
									{/* <Navigate to="/post"> */}
									<button type="submit" className="button green-button">
										Sign In
									</button>
									{/* </Navigate> */}
								</form>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
