import React, { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { db, storage } from "../firebase.config"; // Assuming you have a firebase.config file for configuration
import { useNavigate } from "react-router-dom";

function PhotoUpload() {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const navigate = useNavigate();

	const handleFileChange = (event) => {
		setSelectedFiles(event.target.files);
	};

	const handleUpload = () => {
		setIsUploading(true);
		const promises = [];
		for (const file of selectedFiles) {
			const storageRef = ref(storage, `images/${file.name}`);
			const uploadTask = uploadBytesResumable(storageRef, file);

			promises.push(
				new Promise((resolve, reject) => {
					uploadTask.on(
						"state_changed",
						(snapshot) => {
							const progressPercent = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							);
							setUploadProgress(progressPercent);
						},
						(error) => {
							reject(error);
							setIsUploading(false); // Set isUploading to false on error
						},
						() => {
							getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
								addDoc(collection(db, "photos"), {
									imageUrl: downloadURL,
									// Add other fields as needed
								})
									.then(() => {
										resolve(downloadURL);
									})
									.catch((error) => {
										reject(error);
										setIsUploading(false); // Set isUploading to false on error
									});
							});
						}
					);
				})
			);
		}

		Promise.all(promises)
			.then((urls) => {
				setUploadedImages(urls);
				setIsUploading(false);
				navigate("/confirmation");
				// Handle successful upload, e.g., show success message
			})
			.catch((error) => {
				console.error("Error uploading images:", error);
				setIsUploading(false);
				// Handle upload errors, e.g., show error message
			});
	};

	const handleCancel = () => {
		setSelectedFiles([]);
		setIsUploading(false);
		setUploadProgress(0);
	};

	return (
		<div className="box-layout">
			<div className="container-about">
				<h1 className="title-un-des">Photos</h1>
				<div className="title-un-icon"></div>
				<br></br>
				<p style={{ textAlign: "center" }}>Upload Photos Here.</p>
				<div className="container">
					<div className="newsletter-container wow fadeInUp">
						<div>
							<form className="eventblock">
								<div>
									<label for="photoinput" className="button">
										Upload Photos
									</label>
									<input
										id="photoinput"
										style={{ display: "none" }}
										type="file"
										multiple
										onChange={handleFileChange}
									/>
								</div>
								<br></br>
								<button
									className="button green-button"
									onClick={handleUpload}
									disabled={isUploading}
								>
									{isUploading ? "Uploading..." : "Upload"}
								</button>
								&nbsp;
								<button
									className="button"
									onClick={handleCancel}
									disabled={!isUploading}
								>
									Cancel
								</button>
							</form>

							{isUploading && (
								<div className="upload-progress">
									Uploading... {uploadProgress}%
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PhotoUpload;
