import React from "react";
import { useNavigate } from "react-router-dom";

function ConfirmationPage() {
	const navigate = useNavigate();
	return (
		<div class="container-about">
			<div className="backbutton">
				<p onClick={() => navigate("/dashboard")}>&larr; Back to Dashboard</p>
			</div>
			<h1 class="title-un-des">Upload Successful</h1>
			<br />
			<div class="container">
				<div class="newsletter-container wow fadeInUp">
					<div class="title-un-icon"></div>
					<br />
				</div>
			</div>
		</div>
	);
}

export default ConfirmationPage;
